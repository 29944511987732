@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  --scroll-behavior: smooth;
  overflow-x: hidden !important;
  max-height: 100%;
  height: -webkit-fill-available;
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  min-height: 100vh;
  background-color: var(--bg-primary);
  object-fit: cover;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p,
q {
  font-size: clamp(1rem, 2vw, 1.5rem);
}

h1 {
  font-size: clamp(2rem, 3vw, 2.5rem);
}

:root {
  /* colors */
  --primary-bg: #ffffff;
  --secondary-bg: #989898;
  --light-grey: #f2f2f2;
  --banner-color: #265cad;
  --box-color: #f9b233;
  --box-color-hover: #fe8900;
  --text-color: #3c3c3b;
  --main-title-font-size: clamp(2.5rem, 3.5vw, 10vw);
  --title-font-size: clamp(2rem, 1.5vw, 6rem);
  --paragraph-font-size: clamp(1rem, 1.1rem + 0.05vw, 2.2vw);
  --button-font-size: clamp(1rem, 1.2vw, 4rem);
  --small-text-font-size: clamp(1rem, 0.8vw, 4rem);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
q,
li {
  color: var(--text-color);
}

#nav {
  position: fixed;
  width: 100%;
  top: 0;
  height: 150px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  z-index: 1000;
  background-color: #ffffff;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* padding-top: 150px; */
}
@media screen and (max-width: 1024px) {
  main {
    width: 100%;
    padding-top: 100px;
  }

  #nav {
    height: 100px;
  }
}

a {
  text-decoration: none;
}

/* Safari 11+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    selector {
      property: value;
    }
  }
}
