.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Cookie banner */

.react-cookie-law-container {
  max-width: 1230px !important;
}

.react-cookie-law-dialog {
  position: fixed;
  left: 0px;
  right: 0px;
  z-index: 100000;
  background-color: var(--box-color) !important;
  padding: 10px;
  top: auto !important;
  bottom: 0 !important;
}

.react-cookie-law-accept-all-btn,
.react-cookie-law-accept-selection-btn {
  font-family: 'Poppins', 'Arial';
  background: var(--primary-bg) !important;
  border-color: var(--primary-bg);
  text-transform: uppercase;

  line-height: 24px;
  border-radius: 35px;
}

.react-cookie-law-accept-all-btn span,
.react-cookie-law-accept-selection-btn span {
  font-weight: bold;
  color: var(--text-color);
  padding: 2rem;
}

.react-cookie-law-accept-all-btn:hover,
.react-cookie-law-accept-selection-btn:hover {
  background: var(--box-color-hover) !important;
}

.react-cookie-law-option-wrapper {
  margin-left: 0 !important;
}

.react-cookie-law-option-wrapper label {
  color: black !important;
}

.react-cookie-law-msg,
.react-cookie-law-policy {
  color: var(--black) !important;
}

.react-cookie-law-msg:hover,
.react-cookie-law-policy:hover {
  color: var(--text-color) !important;
}

a.react-cookie-law-policy {
  margin-left: 0 !important;
}

.react-cookie-law-option-checkbox {
  height: 25px;
  width: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid var(--primary-bg);
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: var(--primary-bg);
  cursor: pointer;
}

.react-cookie-law-option-checkbox:checked {
  border: 1px solid var(--box-color-hover);
  background-color: var(--box-color-hover);
}

.react-cookie-law-option-checkbox:checked::before {
  content: '\2713';
  display: block;
  text-align: center;
  color: var(--text-color);
  position: absolute;
  font-weight: 700;
  top: -0.2rem;
  left: 0.1rem;
}

@media screen and (max-width: 1024px) {
  .react-cookie-law-accept-selection-btn {
    margin: 0.5rem;
  }
}
